import en from './en/translation.json'
import ch from './ch/translation.json'
import hi from './hi/translation.json'

const messages = {
  en,
  ch,
  hi

}

export default messages