import React, { useContext, useState } from 'react';
import axios from "axios";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { useStyles } from '../styles';
import Logo from '../components/Logo';
import { setPaymentType, generateQrCode, getCheckoutUrl } from '../actions';
import { Store } from '../Store';
import config,{getParameterByName,merchantCode} from '../Configs'


export default function HomeScreen(props) {
  const { state, dispatch } = useContext(Store);
  const styles = useStyles();
  const { selectedCurrency, order, qr } = state;


  let {setting} = state.userSetting;
    console.log(setting);
    let {userInfo}=state.userData;
console.log(userInfo);

    if(setting){
     setTimeout(() => {
      let textcolor = document.getElementById("title1");
      textcolor.style.color = setting.color_primary;
    }, 10);

   }


 


 let sokURL = window.location.href.indexOf('localhost') > 0 ?'https://sok.menulive.in':window.location.origin;
 const baseURL = config.baseURL;
  const payURL = "https://pay.digitallive24.com";

  const getCurrency = userInfo? userInfo.currency:"";
  const getPercent = setting? setting.taxPercent:"";;


  const [qrDetails, setQrDetails] = useState([])

  let getTime = new Date();
  let closeByTime = Math.floor(getTime.getTime() / 1000) + 180;
  console.log(closeByTime);

  // const [item,setItem] =useState([]);

  let items = [];
  if (order) {
    order.orderItems.map((o) => {
      items.push({
        price_data: {
          currency: getCurrency,
          product_data: {
            name: o.name
          },
          unit_amount: o.price * 100 + (o.price * getPercent / 100) * 100
        },
        quantity: o.quantity
      })
    });
  }

  console.log(items);









  const selectHandler = (paymentType) => {
    setPaymentType(dispatch, paymentType);
    // console.log(dispatch);
    if (paymentType === 'Pay here') {
      if (order.totalPrice) {
        //generateQrCode call this for getting QR code from Razor pay

        // generateQrCode(dispatch,{ order_price:12000,
        //      currency:getCurrency.toUpperCase(),
        //      store_name:"Digitallive24",
        //      description:"so tastey",
        //      closeBy:closeByTime,
        //      order_summary:"order"})

        // this is for Stripe gateway
        getCheckoutUrl(dispatch, {
          items: items,
          success_url: window.location.origin + '/complete?' + window.location.href.split('?')[1],
          cancel_url: window.location.origin + '/review?' + window.location.href.split('?')[1],
          userId: setting?setting.userId:"",
          appName: "sok",
          payType: "onetime",
          payStatus: "unpaid"
        });



        return props.history.push('/payment?' + window.location.href.split('?')[1])
      }
      // props.history.push('/payment?'+window.location.href.split('?')[1]);
    } else {
      props.history.push('/complete?' + window.location.href.split('?')[1]);
    }

  };
  return (
    <Box className={[styles.root, styles.navy]}>
      <Box className={[styles.main, styles.center]}>
        {/* <img src={state.selectedLogo} height='80px' width='100px' /> */}
        <Typography
          className={[styles.center, styles.title3]}
          gutterBottom
          variant="h3"
          component="h3"
          id='title1'

        >
          Select payment type
        </Typography>
        <Box className={styles.cards}>
          <Card className={[styles.card, styles.space]}>
            <CardActionArea onClick={() => selectHandler('Pay here')}>
              <CardMedia
                component="img"
                alt="Pay here"
                image="/images/payhere.png"
                className={styles.media}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h4"
                  color="textPrimary"
                  component="p"
                >
                  PAY HERE
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card className={[styles.card, styles.space]}>
            <CardActionArea onClick={() => selectHandler('At counter')} id='counter'>
              <CardMedia
                component="img"
                alt="At counter"
                image="/images/atcounter.png"
                className={styles.media}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h4"
                  color="textPrimary"
                  component="p"
                 
                >
                  AT COUNTER
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
