import React, { useContext, useEffect, useState } from "react";
import { NumericKeyboard } from "react-numeric-keyboard";

import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import { useStyles } from "../styles";
import Logo from "../components/Logo";
import { Store } from "../Store";
import { Alert } from "@material-ui/lab";
import {
  createOrder,
  setPaymentType,
  generateQrCode,
  clearOrder,
  getCheckoutUrl,
  getUserData,
  setCustomerId,
} from "../actions";
import QRCode from "react-qr-code";
import axios from "axios";
import HomeScreen from "./SelectPaymentScreen";
import config, { getParameterByName, merchantCode } from "../Configs";
import BillPrint from "./BillPrint";
import { useIntl } from "react-intl";


export default function CompleteOrderScreen(props) {
  const styles = useStyles();
  const { state, dispatch } = useContext(Store);
  const { formatMessage: t, locale, setLocale } = useIntl();
  const { order, selectedCurrency } = state;
  const { loading, error, newOrder } = state.orderCreate;
  const [custId, setCustId] = useState("");
  // const {customerId } = state.order;
  const [display, setDisplay] = useState(false);
  const [fontColor, setFontColor] = useState();
  const [displayOrder, setDisplayOrder] = useState(false);
  const [invData, setInvData] = useState();
  const [detailview, setDetailView] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [billPrint, setBillPrint] = useState(false);
  let { setting } = state.userSetting;
  console.log(setting);
  let { userInfo } = state.userData;
  console.log(userInfo);

  const [phnumber, setPhnumber] = useState("");

  console.log(phnumber);
  let sokURL =
    window.location.href.indexOf("localhost") > 0
      ? "https://sok.menulive.in"
      : window.location.origin;
  // const isPaymentAllowed = getParameterByName("isPaymentAllowed");

  const baseURL = config.baseURL;
  const bgColor = getParameterByName("bgColor");
  const userCurrency = userInfo ? userInfo.currency : "";
  const isScan = getParameterByName("isScan");
  const payURL = "https://pay.digitallive24.com";

  console.log(isScan);
  const email = userInfo ? userInfo.email : "";
  const upiId = userInfo ? userInfo.paymentProviderCode : "";
  const upiName = userInfo ? userInfo.firstName : "";
  const gst = userInfo ? userInfo.taxCodeNo : "";
  const [invoiceNo,setInvoiceNo] = useState("")

  let isPaymentAllowed = setting ? setting.isPaymentAllowed : "";
  console.log(isPaymentAllowed);

  let paytimer = null;

  // useEffect(()=>getUserData(dispatch),[]);



  useEffect(() => {
    // getInvNumber();
    console.log(order);
   
    order.orderItems = order.orderItems.map((orderItem) => ({
       ...orderItem,
       sub_pro: typeof orderItem.sub_pro !== "string"
         ? JSON.stringify(orderItem.sub_pro)
         : orderItem.sub_pro,
     }));
    if (
      order &&
      order.orderItems.length > 0 &&
      order.orderType &&
      order.orderType !== "Delivery"
    ) {
      let items = order.orderItems.map((o) => {
        o["status"] = "inProgress";
        return o;
      });
  

      console.log(order.orderType);
      order.orderItems = items;
      // order.paymentType = order.paymentType ? order.paymentType : "At Counter";
      order.paymentType = isPaymentAllowed ? order.paymentType : "At Counter";
      createOrder(dispatch, order);
      // setOpenPhone(false);
    } else {
      if (order && order.orderItems.length > 0) {
        setOpenPhone(true);
      }
    }
  }, [order]);
  console.log(openPhone, order);
  const createNewOrder = (customerId) => {
    if (order.orderItems.length > 0 && phnumber) {
      let items = order.orderItems.map((o) => {
        o["status"] = "inProgress";
        return o;
      });

      console.log(items);
      order.orderItems = items;
      order.paymentType = isPaymentAllowed ? order.paymentType : "At Counter";
      order.customerId = customerId;
      createOrder(dispatch, order);
      console.log(order);
      setPhnumber("");
      setOpenPhone(false);
    }
  };  

  if (newOrder) {
    console.log(newOrder)
    sessionStorage.setItem("newOrder", JSON.stringify(newOrder));
  
  }

  useEffect(() => {
if(newOrder){
  let billData = {};
  billData.userId =newOrder && newOrder[0] ?newOrder[0].userId:"";
  billData.appName = "SOK";
  billData.payType = "onetime";
  billData.payStatus = "paid";
  billData.purchaseItems = JSON.stringify(newOrder && newOrder[0]?newOrder[0].orderItems:"");

  axios
    .post(`${config.payUrl}/api/new-order`, billData)
    .then((res) => {
      console.log(res.data.invoiceData.invoicePath);
      setInvoiceNo(res.data.invoiceData.invoicePath);
    });
}
  }, [newOrder])
  

  // const getInvNumber=()=>{
  //    axios.get(`${payURL}/api/invoice/latest`).then((res) => setInvData(res.data));
  // }

  const handleBack = () => {
    setDisplayOrder(false);
  };
  const handleForm = () => {
    setDisplay(!display);
  };
  const backSpaceImage = () => {
    return (
      <img
        src="/images/backspace-1.png"
        alt="backSpace"
        height="40vh"
        width="40vw"
      />
    );
  };

  let appUrl = window.location.href.replace("complete", "");
  let updateUrl = appUrl.replace(/&/g, "~");
  console.log(updateUrl);

  let orderDetails = JSON.parse(sessionStorage.getItem("newOrder"));
  console.log(orderDetails);

  let summaryPath1 = orderDetails
    ? `${window.location.origin}/app.html?serve_url=${baseURL}&orderId=${
        orderDetails ? orderDetails.id : ""
      }&merchantCode=${
        userInfo ? userInfo.merchantCode : ""
      }&currency=${userCurrency}&restaurant=${upiName}&cgst=${gst}&invoice=${
        invoiceNo
      }&sok_url=${updateUrl}`
    : "";
  console.log(encodeURI(summaryPath1));
  let orderData = {
    orderId:   orderDetails ? orderDetails.id : "",
    merchantCode:  userInfo ? userInfo.merchantCode : "",
    currency: userCurrency,
    restaurant: upiName,
    // address:
    //   userData || merchantData ? merchantData.address || userData.address : "",
    cgst: gst,
    invoice_no: invoiceNo,
  };
  let paymentUrl = orderDetails
    ? `upi://pay?pn=${upiName}&pa=${upiId}&am=${orderDetails.totalPrice}&tn=${orderDetails.number}`
    : "";

  const handleSubmit = () => {
    let msgtext = `${encodeURI(summaryPath1)}`;
    axios
      .post(baseURL.replace("apps", "cms") + "/api/send-sms-msg", {
        toMobile: `+91${phnumber}`,
        msgConent: msgtext,
      })
      .then((res) => {
        setPhnumber("");
      });
  };

  const handlePayment = () => {
    setDetailView(true);
    // axios.post(`${baseURL}/menu/notifications`,
    //       {
    //         userId:setting.userId,
    //         msg:`Amount ${selectedCurrency}${price} received from token #${token}`,
    //         token:token,
    //       }
    //   ).then(res=>console.log(res.data));

    if (paytimer) {
      clearInterval(paytimer);
    }

    createNewOrder();
  };

  const handleCounter = () => {
    if (paytimer) {
      clearInterval(paytimer);
    }
    setDetailView(true);
    createNewOrder();
  };

  const orderStauts = () => {
    let id = orderDetails._id;
    axios.get(`${baseURL}/api/orders/${id}`).then((res) => {
      if (res.data.isPaid) {
        if (paytimer) {
          clearInterval(paytimer);
        }
        setDetailView(true);
      }
    });
  };

  useEffect(() => {
    // if(window.PrintInterface){
    console.log(detailview,isPaymentAllowed);
    if(PrintInterface && detailview || isPaymentAllowed){
      setBillPrint(true);
    }
  }, [detailview, isPaymentAllowed])

  useEffect(() => {
    setTimeout(() => {
      if(billPrint){
        sessionStorage.removeItem("newOrder");
        clearOrder(dispatch);
        props.history.push("/?" + window.location.href.split("?")[1]);
        setBillPrint(true)
       }
    }, 3000);
  
  }, [billPrint])
  
  
  function startTimer() {
    var duration = 60 * 10;
    var timer = duration,
      minutes,
      seconds;
    if (paytimer) clearInterval(paytimer);
    paytimer = setInterval(function () {
      let display = document.getElementById("time");
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      if (--timer < 0) {
        timer = duration;
        if (paytimer) clearInterval(paytimer);
        console.log("Qr Expired");
      }

      if (seconds % 10 === 0) {
        console.log("hello");
        orderStauts();
      }
    }, 1000);
  }

  if (orderDetails && !isPaymentAllowed && !paytimer) {
    // startTimer();
  }

  if (setting) {
    setTimeout(() => {
      let textcolor = document.getElementById("title2");
      textcolor.style.color = setting.color_primary;
    }, 200);
  }

  function validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }

  const handleMobileSubmit = () => {
    if (phnumber) {
      // createNewOrder()

      let data = {
        email: `${phnumber}@menulive.in`,
        phone: phnumber,
        firstName: "No Name",
        lastName: "",
        address: "",
        password: phnumber,
        isEmailVerified: false,
        isPhoneVerified: false,
        referenceDetails: "",
        merchantCode: merchantCode,
      };
      axios
        .post(`${config.authapi}/customer/auth-and-register`, { ...data })
        .then((res) => {
          setCustomerId(dispatch, res.data.user.id);
          setCustId(res.data.user.id);
          createNewOrder(res.data.user.id);
          console.log(res.data);
        });
    }
  };


let PrintInterface = true

  return (
    <Box
      id="paymentroot"
      className={[styles.root1, styles.navy]}
      style={{ backgroundColor: state.selectedBgColor || "white" }}
    >
      {billPrint && <BillPrint orderDetails={orderData} />}
      <Box className={[styles.main1, styles.center]}>
        <Box style={{ marginTop: "30px", padding: "10px" }}>
          {/* <img src={state.selectedLogo} height='80px' width='100px' /> */}
          {!openPhone && !orderDetails && loading ? (
            <CircularProgress></CircularProgress>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <>
              <Typography
                gutterBottom
                variant="h3"
                component="h3"
                id="title2"
                style={{ fontSize: "25px" }}
              >
                {detailview || isPaymentAllowed
                  ?  t({ id: "order_placed" })
                  : t({ id: "process_payment" })}
              </Typography>
              {/* <div className={styles.order}> */}

              {detailview || isPaymentAllowed ? (
                <Typography
                  gutterBottom
                  className={styles.title3}
                  // style={{padding:"50px 100px 0px 0px"}}
                  variant="h3"
                  component="h3"
                >
                  Token #
                  <span className={styles.orderNum}>{orderDetails.number}</span>
                </Typography>
              ) : (
                ""
              )}

              {/* <Typography
                  gutterBottom
                  style={{color:"white", fontSize:"30px"}}
                  variant="h3"
                  component="h3"
                >
                {t({ id: "process_payment" })}
                <QRCode
                  size={456}
                  style={{ height: "150px", maxWidth: "150px", width: "150px", marginTop: "20px", paddingLeft:"20px" }}
                  value={encodeURI(summaryPath1)}
                  viewBox={`0 0 456 456`}
                />{ HomeScreen }
                </Typography> */}
              {/* </div> */}

              <h5 style={{ color: "black", fontWeight: "bold" }}>
                {detailview || isPaymentAllowed
                  ? "To Get Order Summary"
                  : isScan !== "true"
                  ? t({ id: "scan_to_pay" })
                  : ""}
              </h5>
              {!displayOrder && false ? (
                <div style={{ marginTop: "25px" }}>
                  {!display ? (
                    <button className="btn btn-dark" onClick={handleForm}>
                      Enter Your Mobile No.
                    </button>
                  ) : (
                    <Box style={{ display: "flex" }}>
                      <input
                        type="tel"
                        value={phnumber}
                        name="phoneNumber"
                        className="form-control p-3"
                      />

                      {phnumber && false ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setDisplay(false)}
                        >
                          Cancel
                        </Button>
                      )}
                    </Box>
                  )}
                  <br />
                  <br />
                  <span style={{ marginTop: "20px" }}>( or )</span>
                  <div style={{ marginTop: "20px" }}>
                    <button
                      onClick={() => setDisplayOrder(true)}
                      className="btn btn-dark"
                    >
                      Skip I Will Remember
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* QRCODE */}
              {/* !orderDetails && */}
              {!isPaymentAllowed && !detailview ? (
                <div>
                  {isScan !== "true" ? (
                    <QRCode
                      size={456}
                      style={{
                        height: "150px",
                        maxWidth: "150px",
                        width: "150px",
                        marginTop: "20px",
                      }}
                      value={paymentUrl}
                      viewBox={`0 0 456 456`}
                    />
                  ) : (
                    ""
                  )}
                  {isScan === "true" ? (
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <a
                        href={paymentUrl}
                        target="_blank"
                        className="upi_btn"
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                      >
                        UPI Pay
                      </a>
                    </div>
                  ) : (
                    " "
                  )}
                  {/* ,display:"flex",justifyContent:"space-evenly",alignItems:"center" */}
                  <div style={{ width: "400px", margin: "15px" }}>
                    <button
                      className="pay-btn"
                      style={{
                        width: "150px",
                        backgroundColor: "#ffbc01",
                        color: "black",
                      }}
                      onClick={handleCounter}
                    >
                      {t({ id: "pay_at_counter" })}
                    </button>
                    <br />
                    {/* <button className="pay-btn"  onClick={handlePayment}>Cash on delivery</button> */}
                  </div>
                </div>
              ) : (
                ""
              )}

              {detailview || isPaymentAllowed ? (
                <div>
                  <QRCode
                    size={456}
                    style={{
                      height: "150px",
                      maxWidth: "150px",
                      width: "150px",
                      marginTop: "20px",
                    }}
                    value={encodeURI(summaryPath1)}
                    viewBox={`0 0 456 456`}
                  />
                  {false && (
                    <h6 onClick={() => setDisplayOrder(false)}>go back</h6>
                  )}
                  <h6
                    style={{
                      fontWeight: "20px",
                      padding: "12px",
                      fontSize: "20px",
                    }}
                  >
                    Scan (or) Take Snapshot{" "}
                  </h6>
                </div>
              ) : (
                ""
              )}

              {isScan === "true" && (detailview || isPaymentAllowed) ? (
                <div>
                  <h6 style={{ marginTop: "15px" }}> ( OR )</h6>
                  <div style={{ marginTop: "20px" }}>
                    <a
                      href={encodeURI(summaryPath1)}
                      target="_blank"
                      style={{
                        fontSize: "18px",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      Click Here
                    </a>
                  </div>
                </div>
              ) : (
                " "
              )}

              {/* Phone Number  */}

              <Dialog
                // onClose={closeHandler}
                aria-labelledby="max-width-dialog-title"
                style={{ backgroundColor: "#fff !important" }}
                open={openPhone}
                fullWidth={true}
                maxWidth="xs"
                // className='Orderp'
              >
                <DialogTitle id="titorder" className={styles.center}>
                  Enter Mobile Number
                </DialogTitle>
                <div style={{ height: "80px", textAlign: "center" }}>
                  <input
                    type="text"
                    placeholder="Enter Mobile"
                    onChange={(e) => setPhnumber(e.target.value)}
                    onKeyDown={validateNumber}
                    className="number_input"
                    pattern="[1-9]{1}[0-9]{9}"
                  />
                  <button className="number_btn" onClick={handleMobileSubmit}>
                    Submit
                  </button>
                </div>
              </Dialog>

             
            </>
          )}

          <Box>
            <NumericKeyboard
              isOpen={display}
              backSpaceIcon={backSpaceImage()}
              onChange={({ value, name }) => setPhnumber(value)}
            />
          </Box>
        </Box>
      </Box>
      {detailview || isPaymentAllowed || (!orderDetails && !openPhone) ? (
        
        <div
          style={{ display: "grid", placeItems: "center", marginTop: "35px" }}
        >
          <Box style={{marginBottom:"10px"}}>
          </Box>
          <Box>
         
            <Button
              onClick={() => {
                if (paytimer) clearInterval(paytimer);
                sessionStorage.removeItem("newOrder");
                clearOrder(dispatch);
                props.history.push("/?" + window.location.href.split("?")[1]);
                window.location.reload();
              }}
              variant="contained"
              color="primary"
              className={styles.orderAgainBtn}
            >
              Order Again
            </Button>
          </Box>
        </div>
      ) : (
        ""
      )}

      {/* <div style={{ position: "absolute", bottom: "10px", left: "30%" }}>
        <Box  >
          <Button
            onClick={() => {
              clearOrder(dispatch);
              props.history.push('/?' + window.location.href.split('?')[1]);
            }
            }
            variant="contained"
            color="primary"
            className={styles.orderAgainBtn}
          >
            Order Again
          </Button>
        </Box>
      </div> */}
    </Box>
  );
}
