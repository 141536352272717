import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import { addToOrder, removeFromOrder} from '../actions';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useStyles } from '../styles';
import Logo from '../components/Logo';
// import Slider from "react-slick";
import config,{getParameterByName,merchantCode} from '../Configs'

export default function ReviewScreen(props) {
  const styles = useStyles();
  const { state, dispatch } = useContext(Store);
  const {
    orderItems,
    itemsCount,
    totalPrice,
    taxPrice,
    orderType,
  } = state.order;

  const selectedCurrency = state.selectedCurrency;
  console.log(selectedCurrency);

    let {setting} = state.userSetting;
    console.log(setting);

  
  // const isPaymentAllowed = getParameterByName("isPaymentAllowed");
  let isPaymentAllowed = setting?setting.isPaymentAllowed:"";
  console.log(isPaymentAllowed);
  const [quantity, setQuantity] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState({});
  const [addons,setAddons]=useState([]);

  const closeHandler = () => {
    setIsOpen(false);
  };
  const productClickHandler = (p) => {
    console.log(orderItems);
    let existingOrder = orderItems.filter(o => o._id == p._id);
    setProduct(p);
    setAddons(JSON.parse(p.sub_pro));
    if (existingOrder.length) {
      setQuantity(existingOrder[0].quantity);
    } else {
      setQuantity(1);
    }
    setIsOpen(true);
  };

  const addToOrderHandler = () => {
    addToOrder(dispatch, { ...product, quantity });
    setIsOpen(false);
  };

  const cancelOrRemoveFromOrder = () => {
    removeFromOrder(dispatch, product);
    setIsOpen(false);
  };

  const procedToCheckoutHandler = () => {
    // procedToCheckout(dispatch);
    if (isPaymentAllowed) {
      props.history.push('/select-payment?' + window.location.href.split('?')[1]);
    }
    else { 
      props.history.push('/complete?' + window.location.href.split('?')[1]);
    }
  };


   if(setting){
     setTimeout(() => {
      let textcolor = document.getElementById("title1");
      textcolor.style.color = setting.color_primary;
    }, 10);
    
  }

  const adAddons = (adId)=>{
    console.log(product,adId);
    let existingItem= product;
    let adOns = JSON.parse(existingItem.sub_pro).map((ad)=>{
                                                            if(ad._id === adId){
                                                                ad.quantity=ad.quantity+1;
                                                            }
                                                            return ad;
                                                     })
        console.log(adOns);
        setAddons(adOns);
        existingItem.sub_pro=JSON.stringify(adOns);
        setProduct(existingItem);

  }

 const removeAddons = (adId)=>{
  console.log(product,adId);
  let existingItem= product;
  let adOns = JSON.parse(existingItem.sub_pro).map((ad)=>{
                                                          if(ad._id===adId){
                                                              ad.quantity=ad.quantity-1;
                                                          }
                                                          return ad;
                                                   })
      console.log(adOns);
      setAddons(adOns);
        existingItem.sub_pro=JSON.stringify(adOns);
        setProduct(existingItem);

 }




  return (
    <Box className={[styles.root]} >
      <Box className={[styles.main, styles.center]}>
        <Dialog
          onClose={closeHandler}
          aria-labelledby="max-width-dialog-title"
          open={isOpen}
          fullWidth={true}
          // maxWidth="xs"
          maxWidth={state.widthScreen ? 'sm' : 'xs'}
        >
          <DialogTitle className={styles.center}>
            Add {product.name}
          </DialogTitle>
          <Box className={[styles.row, styles.center]}>
            <Button className={styles.minus}
            id='minus_btn'
              variant="contained"
              color="primary"
              disabled={quantity === 1}
              onClick={(e) => quantity > 1 && setQuantity(quantity - 1)}
            >
              <RemoveIcon />
            </Button>
            <TextField
            id='order_count'
              inputProps={{ className: styles.largeInput }}
              className={styles.largeNumber}
              type="number"
              min={1}
              variant="filled"
              value={quantity}
            />
            <Button className={styles.add}
             id='plus_btn'
              variant="contained"
              color="primary"
              onClick={(e) => setQuantity(quantity + 1)}
            >
              <AddIcon />
            </Button>
          </Box>
          <Box style={{margin:"10px"}}>
            <h5 style={{textAlign:"center"}}>{addons.length>=1?"Add-ons":""}</h5>
            { addons.length>=1?addons.map(li=> (
              <div style={{display:"flex",justifyContent:"space-between",alignContent:"center",padding:"3px 15px",fontSize:"20px",flexWrap:"wrap"}}>
                {li.name} <div className={styles.btn_group} id='btn_group'>
                    <span>{selectedCurrency} {li.price} </span>
                            <span className={styles.addons} id='addons'>
                            <button className={styles.minus1} onClick={()=>removeAddons(li._id)}><RemoveIcon /></button>
                            {li.quantity} 
                            <button className={styles.plus} onClick={()=>adAddons(li._id)}> <AddIcon sx={{ fontSize:"1px"}} /></button>
                            </span>
                           </div> 
              </div> 
            ))
              :""}
          </Box>
          <Box className={[styles.row, styles.around]}>

            <Button
              onClick={cancelOrRemoveFromOrder}
              variant="outlined"
              color="primary"
              size="large"
              className={[styles.largeButton, styles.card]}
            >
              {orderItems.find((x) => x.name === product.name)
                ? 'Remove'
                : 'Cancel'}
            </Button>

            <Button
              onClick={addToOrderHandler}
              variant="contained"
              color="primary"
              size="large"
              className={styles.largeButton}
            >
              ADD 
            </Button>
          </Box>
        </Dialog>
        <Box className={[styles.center, styles.around]}>
          {/* <img src={state.selectedLogo} height='90px' width='100px' /> */}
          <Typography
            gutterBottom
            // className={styles.title}
            className={styles.title3}
            variant="h3"
            component="h3"
            id="title1"
          >
            Review my {orderType} order
          </Typography>
        </Box>
        <Box  style={{overflowY:"auto"}}>
        <Grid container >
          {orderItems.map((orderItem) => (
            <Grid item md={12} sm={12} xs={12} key={orderItem.name}>
              <Card
                className={[styles.card, styles.editCard]}
                onClick={() => productClickHandler(orderItem)}
              >
                
                <CardActionArea  >
                  <CardContent>
                    <Box className={[styles.row, styles.between]}>
                      <div>
                        <Typography
                          gutterBottom
                          variant="h6"
                          className={styles.reviwText}
                          component="p"
                        >
                          {orderItem.name}

                        </Typography>
                      </div>
                      <div className='amount' style={{position:"absolute",left:"50%"}}>
                        <h3>{orderItem.quantity} x {selectedCurrency}{orderItem.price}</h3>
                      </div>
                      <div>
                        <Button variant="contained" color="primary" className={styles.editBtn}>Edit</Button>
                      </div>
                    </Box>
                    <Box className={[styles.row, styles.between]}>
                      <Typography
                        variant="subtitle1"
                        // color="textSecondary"
                        component="p"
                        className={styles.prod_cal1}
                      >
                        {orderItem.calorie} Cal
                      </Typography>
                      <Typography
                        variant="h5"
                        className={styles.reviwText}
                        component="p"
                      >
                        {/* {orderItem.quantity} x {selectedCurrency}{orderItem.price} */}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        </Box>
      </Box>
      <Box>
        <Box>
          <Box className={[styles.bordered, styles.space]} style={{ fontSize: '28px', textAlign: "center", lineHeight: "30px", paddingTop: "10px" }}>
            My Order - {orderType} | Tax:
            {selectedCurrency}{taxPrice} | Total: {selectedCurrency}{totalPrice} | Items: {itemsCount}
          </Box>
          <Box className={[styles.row, styles.around]}>
            <Button
              onClick={() => {
                props.history.push(`/order?` + window.location.href.split('?')[1]);
              }}
              variant="outlined"
              color="primary"
              className={[styles.largeButton]}
              id='review_clbtn'
            >
              Back
            </Button>

            <Button
              onClick={procedToCheckoutHandler}
              variant="contained"
              color="primary"
              disabled={orderItems.length === 0}
              className={styles.largeButton}
            >
               Checkout
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
