import React, { useContext, useEffect, useState } from "react";
import { Store } from "../Store";
import axios from "axios";
import Slider from "react-slick";
import {
  addToOrder,
  clearOrder,
  listCategories,
  listProducts,
  removeFromOrder,
  getUserData,
} from "../actions";

import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Chip from "@mui/material/Chip";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  ListItem,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Checkbox from "@mui/material/Checkbox";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Alert } from "@material-ui/lab";
import { useStyles } from "../styles";
import Logo from "../components/Logo";
import config, { getParameterByName, merchantCode } from "../Configs";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useIntl } from "react-intl";

export default function OrderScreen(props) {
  const styles = useStyles();
const { formatMessage: t, locale, setLocale } = useIntl();
  const { state, dispatch } = useContext(Store);
  const { categories, loading, error } = state.categoryList;
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cookalignment, setCookAlignment] = useState([]);
  const [cookInst, setCookInst] = useState([]);

  const {
    products,
    loading: loadingProducts,
    error: errorProducts,
  } = state.productList;

  const { orderItems, itemsCount, totalPrice, taxPrice, orderType } =
    state.order;

  console.log(state.order);

  const selectedCurrency = state.selectedCurrency;
  console.log(state.order);
  let cat_time = null;
  console.log(state.userSetting);

  const [totalProducts, setTotalProducts] = useState([]);
  const [totalAddons, setTotalAddons] = useState([]);
  const [itemGroup, setItemGrorp] = useState([]);
  const [procheckbox, setProCheckBox] = useState([]);
  const [filterItmGrp, setFilterItmGrp] = useState([]);
  const [itemGrpId, setItemGrpId] = useState("");
  const [addonsGroup, setAddonsGroup] = useState([]);
  const [catId, setCatId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [imageURL, setImageURL] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isVegOnly, setIsVegOnly] = useState(false);
  const [addons, setAddons] = useState([]);
  const [subPro, setSubPro] = useState([]);
  const [alignment, setAlignment] = useState("0");
  const [variety, setVariety] = useState([]);
  const [selectedLogo, setSelectedLogo] = useState("../images/logo.png");
  const [isCartOpen, setIsCartOpen] = useState(false);

  const baseURL = config.baseURL;
  console.log(products);
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  let filteredProducts = [];

  useEffect(() => {
    filteredProducts = totalProducts.filter(
      (p) =>
        (isVegOnly && p.cat_type.toUpperCase() === "VEG" && p.inStock) ||
        (!isVegOnly && p.inStock)
    );
    console.log(filteredProducts);
  }, [totalProducts]);

  console.log(filteredProducts);
  let { setting } = state.userSetting;
  let { userInfo } = state.userData;
  console.log(setting, userInfo);
  if (setting) {
    let body = document.getElementsByTagName("body");
    //body[0].style.backgroundColor = setting.color_tirnary;
    setTimeout(() => {
      let textcolor = document.getElementById("title1");
      textcolor.style.color = setting.color_primary;
    }, 10);
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [product, setProduct] = useState([]);
  console.log(product);

  const closeHandler = () => {
    setIsOpen(false);
    setIsCartOpen(false);
  };

  const vegHandler = () => {
    console.log(products);
    setIsVegOnly(!isVegOnly);
  };

  console.log(addons);
  useEffect(() => {}, []);
  console.log(variety);

  useEffect(() => {
    if (!totalProducts.length) {
      axios
        .get(`${baseURL}/api/products?merchantCode=${merchantCode}`)
        .then((res) => {

          const isStock = res.data.filter(stock => stock.inStock === true)
          console.log(isStock)
          setTotalProducts(isStock)});
    }
  });

  useEffect(() => {
    console.log(totalProducts);
    console.log(categories);
    let addons = [];
    categories &&
      categories.map((c) => {
        if (c.isAddOn) {
          addons.push(c.id);
        }
      });
    setAddonsGroup(
      totalProducts.filter((pro) => addons.indexOf(pro.category) != -1)
    );
    if (totalProducts.length) {
      let items = [];
      totalProducts.filter((it) => {
        categories.map((p) => {
          if (p.id === it.category) {
            items.push(it);
            console.log(it);
          }
        });
      });
      console.log([...new Set(items)]);
      setFilterItmGrp([...new Set(items)]);
    } else if (products && products.length) {
      // setFilterItmGrp(itemGroup.filter(i=>i.category===catId?catId:categories[0]._id?categories[0]._id:categories[0].id));
      setFilterItmGrp(products);
    }
  }, [products]);
  console.log(products);
  console.log(filterItmGrp);

  const productClickHandler = (p) => {
    // console.log(p._id);
    console.log(addonsGroup);
    console.log(orderItems);

    if (p.varietyPrices || p.cookInstructions || p.addons) {
      setVariety(JSON.parse(p.varietyPrices));
      setCookInst(p && p.cookInstructions ? p.cookInstructions.split(",") : []);
    }

    let existingOrder = orderItems.filter((o) => o.id === p.id);
    if (existingOrder.length) {
      setQuantity(existingOrder[0].quantity);
    } else {
      setQuantity(1);
    }

    if (existingOrder.length > 0) {
      if (existingOrder[0].sub_pro) {
        let adongrp = [];
        existingOrder[0].add_ons.split(",").map((i) => {
          addonsGroup.map((ad) => {
            if (i === ad.id) {
              adongrp.push(ad);
            }
          });
        });
        let subP = [];
        let exItem = [];
        let exAd =
          typeof existingOrder[0].sub_pro === "string"
            ? JSON.parse(existingOrder[0].sub_pro)
            : existingOrder[0].sub_pro;

        adongrp.map((p) => {
          exAd.map((li) => {
            if (p.id === li.id) {
              // p.quantity=li.quantity;
              subP.push(p);
            }
          });
          console.log(p);
          exItem.push(p);
        });
        console.log(subP);
        setSubPro(subP);
        console.log(exItem);
        setAddons(exItem);
      }
    } else {
      console.log(p.add_ons.split(","));
      let adongrp = [];
      p.add_ons.split(",").map((i) => {
        addonsGroup.map((ad) => {
          if (i === ad.id) {
            adongrp.push(ad);
          }
        });
      });
      setAddons(adongrp);
    }

    setProduct(p);
    console.log(p);
    // const paddon = p.add_ons.split(",").filter(a => a.length);
    // console.log(paddon)
    // console.log(addonsGroup)
    // let addName = addonsGroup.filter(li => paddon.indexOf(li.id) != -1);
    // console.log(addName)
    // setAddons(addName);
    setSelectedProduct(p);
    setIsOpen(true);
  };

  console.log(addonsGroup);
  console.log(addons);
  product["sub_pro"] = {};
  const addToOrderHandler = () => {
    console.log(product);

    let adons = totalAddons.map((ad) => {
      return ad;
    });

    const selectedVarietyPrice = Object.keys(variety).reduce((acc, key) => {
      if (key === alignment) {
        acc = { nameVariety: key, priceVariety: variety[key] };
      }
      return acc;
    }, 0);
    product.price = selectedVarietyPrice
      ? selectedVarietyPrice.priceVariety
      : product.price;
    console.log(product);
    const selectedInstruction = cookInst.reduce((acc, inst) => {
      if (inst === cookalignment) {
        acc = { nameInstruction: inst };
      }
      return acc;
    }, {});
    console.log(cookInst);
    product.sub_pro.addons = [...subPro];
    product.sub_pro.variety = selectedVarietyPrice ? selectedVarietyPrice : [];
    product.sub_pro.cookInstructions = selectedInstruction;
    console.log(subPro);
    console.log(product);

    if (variety) {
      const varietyPrice = parseFloat(selectedVarietyPrice);
      product.price = varietyPrice ? varietyPrice : product.price;
    }

    console.log(selectedProduct);

    addToOrder(dispatch, { ...product, quantity });

    console.log(orderItems);

    setTotalAddons(adons);
    setAddons([]);
    setQuantity(1);
    setSubPro([]);
    setSelectedProduct();
    // setAddons([]);
    setIsOpen(false);
  };
  const cancelOrRemoveFromOrder = () => {
    let adons = totalAddons.map((ad) => {
      ad.quantity = 0;
      return ad;
    });

    setTotalAddons(adons);
    setAddons([]);
    setSubPro([]);
    removeFromOrder(dispatch, product);
    setIsOpen(false);
  };

  const previewOrderHandler = () => {
    props.history.push("/complete?" + window.location.href.split("?")[1]);
  };

  useEffect(() => {
    getUserData(dispatch);
  }, []);

  console.log(!categories);

  useEffect(() => {
    if (!categories) {
      listCategories(dispatch);
      console.log(listCategories(dispatch));
    } else {
      listProducts(
        dispatch,
        categories[0]._id ? categories[0]._id : categories[0].id
      );
    }
  }, [categories]);

  const categoryClickHandler = (cat_name, cat_id) => {
    console.log(cat_id);
    setCatId(cat_id);
    setCategoryName(cat_name);
    listProducts(dispatch, cat_id);
    setQuantity(1);
  };

  const removeAddons = (itemId) => {
    let filterItems = addons.filter((i) => (i._id ? i._id : i.id) === itemId);
    let existingItem = subPro.filter((p) => (p._id ? p._id : p.id) === itemId);
    console.log(subPro);
    console.log(existingItem);
    if (existingItem.length === 0) {
      filterItems[0].quantity =
        filterItems[0].quantity > 0
          ? filterItems[0].quantity - 1
          : filterItems[0].quantity;
    }
    console.log(filterItems);

    const items =
      existingItem.length > 0
        ? subPro.map((x) => {
            if ((x._id ? x._id : x.id) === itemId) {
              console.log(x.quantity, existingItem[0].quantity);
              x.quantity = x.quantity > 0 ? x.quantity - 1 : x.quantity;
            }
            return x;
          })
        : [...subPro, filterItems[0]];

    setSubPro(items);
  };

  const adAddons = (e, itemId, index) => {
    const updatedCheckBox = [...procheckbox];
    updatedCheckBox[index] = e.target.checked;
    console.log(e.target.checked, updatedCheckBox[index]);
    setProCheckBox(updatedCheckBox);

    const filterItems = addons.filter((i) => (i._id ? i._id : i.id) === itemId);
    console.log(filterItems);

    const existingItem = subPro.find((p) => (p._id ? p._id : p.id) === itemId);
    console.log(existingItem);

    if (
      existingItem &&
      (existingItem._id ? existingItem._id : existingItem.id)
    ) {
      let removeAddOns = subPro.filter(
        (ad) => (ad._id ? ad._id : ad.id) !== itemId
      );
      setSubPro(removeAddOns);
    } else {
      setSubPro([...subPro, ...filterItems]);
    }
  };

  const handleCart = () => {
    console.log("Cart");
    setIsCartOpen(true);
  };

  const imageOnErrorHandler = (event) => {
    event.currentTarget.src = "./images/blank.jpg";
  };

  const itemGroupHandle = (itmgrpId) => {
    setItemGrpId(itmgrpId);
  };
  useEffect(() => {
    console.log(totalProducts);
    console.log(categories);
    if (totalProducts.length) {
      let items = [];
      totalProducts.filter((it) => {
        categories.map((p) => {
          console.log(p);
          console.log(it);

          if (it.category === p.id) {
            if (isVegOnly & (it.cat_type.toLowerCase() == "veg")) {
              items.push(it);
              console.log(it);
            } else {
              items.push(it);
              console.log(it);
            }
          }
        });
      });
      console.log(items);
      console.log([...new Set(items)]);
      setFilterItmGrp([...new Set(items)]);
    } else if (products && products.length) {
      // setFilterItmGrp(itemGroup.filter(i=>i.category===catId?catId:categories[0]._id?categories[0]._id:categories[0].id));
      setFilterItmGrp(products);
    }
  }, [products]);
  let ItemsWithGrp = filterItmGrp.length ? filterItmGrp : totalProducts;
  console.log(ItemsWithGrp);
  const itemsfilt = totalProducts.filter(
    (proitem) => proitem.category === catId
  );
  console.log(itemsfilt);
  console.log(ItemsWithGrp);

  const showVarietyBtn = (variety) => {
    return (
      <ToggleButtonGroup
        value={alignment}
        onChange={handleAlignment}
        exclusive
        aria-label="text alignment"
        style={{ backgroundColor: "white" }}
      >
        {Object.keys(variety).map((key, index) => (
          <ToggleButton
            style={{ display: "inline-block" }}
            value={key}
            aria-label="left aligned"
          >
            <div style={{ display: "block", width: "100%" }}>{key}</div>
            <div
              style={{ color: "#000", fontWeight: "bold", fontSize: "1.2em" }}
            >
              {selectedCurrency + variety[key]}
            </div>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  };

  const handleCookAlignment = (event, newAlignment) => {
    setCookAlignment(newAlignment);
  };

  const showinstructionBtn = () => {
    return (
      <ToggleButtonGroup
        value={cookalignment}
        onChange={handleCookAlignment}
        exclusive
        aria-label="text alignment"
        style={{ backgroundColor: "white" }}
      >
        {Object.values(cookInst).map((key, index) => (
          <ToggleButton
            style={{ display: "inline-block" }}
            value={key}
            aria-label="left aligned"
          >
            <div style={{ display: "block", width: "100%" }}>{key}</div>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  };

  return (
    <Box className={styles.root} style={{ backgroundColor: "white" }}>
      <div className="cart_dox" onClick={handleCart}>
      <span className="cart_count">
          {itemsCount}
        </span>
        <div >
        <ShoppingBagIcon sx={{ cursor: "pointer", color: "white" }} />
        </div>
       
      </div>
      
      <Dialog
        onClose={closeHandler}
        aria-labelledby="max-width-dialog-title"
        style={{ backgroundColor: "#fff !important" }}
        open={isCartOpen}
        fullWidth={true}
        maxWidth="lg"
        className="Orderp"
      >
        <div className="cart_header">
          <span
            onClick={() => {
              setIsCartOpen(false);
            }}
            className="back_arrow"
          >
            <img
              src="images/back.png"
              alt="BigCo Inc. logo"
              style={{ width: "20px", height: "20px" }}
            />
          </span>
          {t({ id: "my_order" })}
        </div>
        <div className="cart_sub" id="cart_s">
          <div
            className="cart_items"
            id="item1"
            style={{ maxHeight: "480px", overflowY: "auto" }}
          >
            <table cellPadding="2px" cellSpacing="5px" id="tab1">
              <thead>
                <tr>
                  <th id="td1">Item Name</th>
                  <th id="td2">Quantity</th>
                  <th id="td3">Price</th>
                  <th colSpan="2"></th>
                </tr>
              </thead>
              <tbody>
                {orderItems.length
                  ? orderItems.map((orderItem) => {
                      const subProArray =
                        typeof orderItem.sub_pro === "string"
                          ? JSON.parse(orderItem.sub_pro)
                          : orderItem.sub_pro;

                      console.log(subProArray);

                      const subProNames =
                        subProArray && subProArray.addons
                          ? subProArray.addons.map((subPro) => subPro.name)
                          : [];
                      const subProInstruction =
                        subProArray && subProArray.cookInstructions
                          ? subProArray.cookInstructions.nameInstruction
                          : "";
                      const subProvarieties =
                        subProArray && subProArray.variety
                          ? subProArray.variety.nameVariety
                          : "";
                      const addonTotalPrice =
                        subProArray && subProArray.addons
                          ? subProArray.addons.reduce((acc, val) => {
                              if (val.price) {
                                return acc + parseFloat(val.price);
                              }
                              return acc;
                            }, 0)
                          : 0;
                      console.log(orderItem.sub_pro);
                      console.log(subProArray);
                      return (
                        <tr
                          id="tr"
                          style={{
                            borderBottom: "2px solid #FFBC00",
                            fontSize: "15px",
                          }}
                        >
                          <td id="td1">
                            <b>{orderItem.name} </b>
                            <br />
                            {subProNames.length > 0 ? (
                              <Chip
                                label={subProNames.join(", ").toUpperCase()}
                                color="default"
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                }}
                              />
                            ) : (
                              subProNames
                            )}{" "}
                            {subProInstruction ? (
                              <Chip
                                label={subProInstruction.toUpperCase()}
                                color="default"
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "8px",
                                  fontWeight: "bold",
                                }}
                              />
                            ) : (
                              ""
                            )}
                            {subProvarieties ? (
                              <Chip
                                label={subProvarieties.toUpperCase()}
                                color="default"
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                          <td id="td2">{orderItem.quantity}</td>
                          <td id="td3">
                            {selectedCurrency}
                            {(Number(orderItem.price) || 0) +
                              (subProArray && subProArray.addons
                                ? subProArray.addons.reduce(
                                    (acc, val) =>
                                      acc + (Number(val.price) || 0),
                                    0
                                  )
                                : 0)}
                          </td>

                          <td id="td4">
                            <EditIcon
                              className="cart_edit"
                              onClick={() => productClickHandler(orderItem)}
                            />
                          </td>
                          <td id="td5">
                            {" "}
                            <RemoveCircleOutlineIcon
                              className="cart_remove"
                              onClick={() =>
                                removeFromOrder(dispatch, orderItem)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </div>
          <div className="cart_items" id="item2">
            <h3
              id="h3"
              style={{
                fontSize: "25px",
                marginTop: "14px",
                marginLeft: "21px",
              }}
            >
              {t({ id: "bill_summary" })}
            </h3>
            <table style={{ width: "100%", fontSize: "20px" }} id="tab1">
              <tr>
                <td>Sub - total :</td>
                <td align="end">
                  {selectedCurrency} {(totalPrice - taxPrice).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Tax :</td>
                <td align="end">
                  {selectedCurrency} {taxPrice.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Discount :</td>
                <td align="end">{selectedCurrency} 0.00</td>
              </tr>
            </table>
            <div style={{ position: "relative", top: "120px" }} id="total">
              <table
                style={{
                  width: "100%",
                  fontSize: "20px",
                  marginBottom: "30px",
                }}
              >
                <tr>
                  <td>
                    {" "}
                    <b> {t({ id: "total_amt" })} :</b>
                  </td>
                  <td align="end">
                    {selectedCurrency}
                    {totalPrice.toFixed(2)}
                  </td>
                </tr>
              </table>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <button
                  className="cart_cancle"
                  id="subbtn"
                  onClick={() => {
                    // clearOrder(dispatch);
                    setIsCartOpen(false);
                  }}
                >
                  {t({ id: "cancel" })}
                </button>
                <button
                  className="cart_procee"
                  id="subbtn"
                  disabled={!orderItems.length}
                  onClick={previewOrderHandler}
                >
                  {t({ id: "Next" })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "5%",
        }}
      >
        <span
          onClick={() => {
            props.history.push(`/choose?` + window.location.href.split("?")[1]);
          }}
        >
          <img
            src="images/back-btn.png"
            alt="BigCo Inc. logo"
            style={{ width: "35px", height: "35px", margin: "5px" }}
          />
        </span>
        <div className="logo">
          {userInfo && userInfo.logoImg ? (
            <img src={userInfo.logoImg} id="imglogo" />
          ) : (
            <h1>Logo</h1>
          )}
        </div>
      </div>
      <Slider {...settings}>
        <div className={styles.categoryContainer} id="cate-item">
          {categories &&
            categories.map((category) => (
              <div
                className={styles.categoryItem}
                id="cont"
                onClick={() =>
                  categoryClickHandler(
                    category.name,
                    category._id ? category._id : category.id
                  )
                }
              >
                <div className={styles.imageHolder} id="imageHolder">
                  <img
                    className={styles.image}
                    alt={category.name}
                    id="image"
                    onError={imageOnErrorHandler}
                    src={`${baseURL}/` + category.image}
                  />
                </div>
                <div>{category.name}</div>
              </div>
            ))}
        </div>
      </Slider>

      <Typography
        gutterBottom
        className={[styles.title, styles.catTitleBox]}
        variant="h2"
        component="h2"
        id="title1"
        style={{ marginBottom: "20px", marginLeft: "20px" }}
      >
        {categoryName || t({ id: "explore_menu" })}
        {/* <div className={styles.vegBtn} style={{ float: "right" }}>
          <FormGroup>
            <FormControlLabel control={<Switch size='medium' defaultUnChecked color='success' />} onChange={vegHandler} style={{ color: "green", fontWeight: "400px" }} label="Only Veg" id="veg" />
          </FormGroup>
        </div> */}
      </Typography>
      {/* <div className={styles.cont} id="btns">
        <div className={styles.btnHolder}
          id='btnc1'
        >
          All
        </div>
        <div className={styles.btnHolder} id='btnc2'>
          Special
        </div>
        <div className={styles.btnHolder}
          style={{ backgroundColor: "#FFBC00", color: "#000" }} id='btnc3'
        >
          Popular
        </div>
      </div> */}

      <Box id="porder" className={styles.main}>
        <Dialog
          onClose={closeHandler}
          aria-labelledby="max-width-dialog-title"
          style={{ backgroundColor: "#FFBC00 !important" }}
          open={isOpen}
          fullWidth={true}
          maxWidth={state.widthScreen ? "sm" : "xs"}
        >
          <DialogTitle id="titorder" className={styles.center}>
            <Card
              className={styles.cardd}
              style={{
                width: "252px",
                height: "280px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <CardMedia
                component="img"
                alt={product.name}
                image={`${baseURL}/` + product.image}
                className={styles.media}
                onError={imageOnErrorHandler}
                style={{ paddingTop: "10px !important" }}
              />
              {product.name} <br />
              {selectedCurrency} {product.price}
            </Card>
          </DialogTitle>
          <Box id="btnorder" className={[styles.countRow, styles.center]}>
            <Button
              className={styles.minus}
              id="minus_btn"
              variant="contained"
              color="primary"
              disabled={quantity === 1}
              onClick={(e) => quantity > 1 && setQuantity(quantity - 1)}
            >
              <RemoveIcon />
            </Button>
            <TextField
              inputProps={{ className: styles.largeInput }}
              InputProps={{
                bar: true,
                inputProps: {
                  className: styles.largeInput,
                },
              }}
              // className={styles.largeNumber}
              className="largeNumber"
              type="number"
              variant="filled"
              min={1}
              value={quantity}
            />

            <Button
              className={styles.add}
              id="plus_btn"
              variant="contained"
              color="primary"
              onClick={(e) => setQuantity(quantity + 1)}
            >
              <AddIcon sx={{ fontSize: "1px" }} />
            </Button>
          </Box>
          <Box id="adionorder" style={{ margin: "10px" }}>
            <h4 style={{ textAlign: "center" }}> {t({ id: "select_size" })}</h4>
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              {showVarietyBtn(variety)}
            </div>

            <h4 style={{ textAlign: "center" }}>
              {t({ id: "select_cook_instruction" })}
            </h4>
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              {showinstructionBtn(cookInst)}
            </div>

            <h4 style={{ textAlign: "center" }}>
              {addons.length >= 1 ? "Add-ons" : ""}
            </h4>
            {addons.length >= 1
              ? addons.map((li, i) => {
                  let exitAd = subPro.filter(
                    (sub) => sub.name.indexOf(li.name) !== -1
                  );
                  console.log(exitAd);
                  return (
                    <div
                      id="addons"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        padding: "3px 15px",
                        fontSize: "20px",
                        flexWrap: "wrap",
                      }}
                    >
                      <span id="addname"> {li.name} </span>
                      <div>
                        <span id="pri" i>
                          {selectedCurrency} {li.price}
                          <Checkbox
                            id={`checkboxId-${i}`}
                            defaultChecked={exitAd.length}
                            onChange={(e) =>
                              adAddons(e, li._id ? li._id : li.id, i)
                            }
                          />
                        </span>
                      </div>
                    </div>
                  );
                })
              : ""}
          </Box>
          <Box id="footorder" className={[styles.row, styles.around]}>
            <Button
              onClick={cancelOrRemoveFromOrder}
              size="large"
              className={[styles.AddlargeButton]}
              style={{
                backgroundColor: "#FFF !important",
                color: "#000 !important",
              }}
              id="btcart"
            >
              {orderItems.find((x) => x.name === product.name)
                ? t({ id: "remove" })
                : t({ id: "cancel" })}
              {/* Add */}
            </Button>

            <Button
              onClick={addToOrderHandler}
              variant="contained"
              size="large"
              className={styles.rightlargeButton}
              id="btcart"
            >
              {t({ id: "add" })}
            </Button>
          </Box>
        </Dialog>
        <Grid container>
          <Grid item md={12} xs={12}>
            <Grid container spacing={1} id="pro_continer">
              {catId
                ? itemsfilt.map((it) => {
                    return (
                      <>
                        <Slide key={it.name} direction="up" in={true}>
                          <Card
                            className={styles.card}
                            onClick={() =>
                              it["userId"]
                                ? productClickHandler(it)
                                : itemGroupHandle(it.id)
                            }
                            style={{ width: "252px" }}
                            id="product"
                          >
                            <CardActionArea
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingTop: "4px",
                              }}
                            >
                              <CardMedia
                                component="img"
                                alt={it.name}
                                image={`${baseURL}/` + it.image}
                                className={styles.media}
                                id="img-product"
                                onError={imageOnErrorHandler}
                              />

                              {it["cat_type"] ? (
                                <div className={styles.veg_img}>
                                  {it.cat_type.toUpperCase() === "VEG" ? (
                                    <img
                                      src="/images/veg.png"
                                      width="30px"
                                      height="30px"
                                    />
                                  ) : (
                                    <img
                                      src="/images/Non-veg.png"
                                      width="30px"
                                      height="30px"
                                    />
                                  )}
                                </div>
                              ) : (
                                " "
                              )}
                              <CardContent className={styles.cardFooter}>
                                <Box className={styles.foot}>
                                  <Typography
                                    gutterBottom
                                    className={styles.prod_title}
                                    variant="h6"
                                    color="primary"
                                    component="p"
                                    id="pro_name"
                                    // style={{ whiteSpace: "nowrap",overflow: "hidden", textOverflow: "ellipsis" }}
                                  >
                                    {it.name}
                                  </Typography>
                                  {it.price ? (
                                    <div
                                      id="pro_price"
                                      style={{
                                        width: "auto",
                                        bottom: "36px",
                                        right: "1px",
                                        padding: "5px",
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      <h3 style={{ margin: "0px" }}>
                                        {selectedCurrency}
                                        {it.price}
                                      </h3>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Slide>

                        <Dialog
                          onClose={() => setItemGrpId("")}
                          aria-labelledby="max-width-dialog-title"
                          fullScreen="sm"
                          open={itemGrpId === it.id}
                          fullWidth={true}
                          // maxWidth="sm"
                          maxWidth={state.widthScreen ? "lg" : "md"}
                        >
                          <AppBar
                            sx={{ position: "relative", marginBottom: "18px" }}
                          >
                            <Toolbar>
                              {/* <IconButton
                                      edge="start"
                                      color="error"
                                      style={{fontSize:"18px"}}
                                      onClick={()=>setItemGrpId("")}
                                      aria-label="close"
                                    >
                                    Back
                                    </IconButton> */}
                              <Typography
                                style={{ marginLeft: "25px" }}
                                color="secondary"
                                sx={{ ml: 2, flex: 1 }}
                                variant="h5"
                                component="h5"
                              >
                                {it.name}
                              </Typography>
                            </Toolbar>
                          </AppBar>

                          <Grid
                            container
                            spacing={1}
                            sx={{ height: "80%", padding: "15px" }}
                          >
                            {filterItmGrp
                              .filter((p) => p.id === itemGrpId)
                              .map((item) => {
                                return item.items.elements
                                  .filter((p) => p.available)
                                  .map((product) => {
                                    return (
                                      <>
                                        <Slide
                                          key={product.name}
                                          direction="up"
                                          in={true}
                                        >
                                          <Grid
                                            item
                                            xl={2}
                                            lg={3}
                                            md={3}
                                            sm={4}
                                            xs={6}
                                          >
                                            <Card
                                              className={styles.card}
                                              onClick={() =>
                                                productClickHandler(product)
                                              }
                                            >
                                              <CardActionArea>
                                                <CardMedia
                                                  component="img"
                                                  alt={product.name}
                                                  onError={imageOnErrorHandler}
                                                  image={
                                                    `${baseURL}/` +
                                                    product.image
                                                  }
                                                  className={styles.media}
                                                />
                                                <div>
                                                  <h3 style={{ margin: "0px" }}>
                                                    {selectedCurrency}
                                                    {product.price}
                                                  </h3>
                                                </div>

                                                <div className={styles.veg_img}>
                                                  {/* {product.cat_type.toUpperCase() === "VEG" ?
                                                <img
                                                  src="/images/veg.png"
                                                  width="30px" height="30px"
                                                />
                                                : <img src="/images/Non-veg.png" width="30px" height="30px" />
                  
                                              } */}
                                                </div>
                                                <CardContent
                                                  className={styles.cardFooter}
                                                  style={{ display: "block" }}
                                                >
                                                  <Box className={styles.foot}>
                                                    <Typography
                                                      gutterBottom
                                                      className={
                                                        styles.prod_title
                                                      }
                                                      variant="h6"
                                                      color="primary"
                                                      component="p"
                                                      // style={{width:"150px"}}
                                                    >
                                                      {product.name}
                                                    </Typography>

                                                    {/* <Typography
                                                  gutterBottom
                                                  variant="p"
                                                  color=""
                                                  component="p"
                                                >
                                                    {product.calories}cal
                                                </Typography> */}
                                                  </Box>
                                                </CardContent>
                                              </CardActionArea>
                                            </Card>
                                          </Grid>
                                        </Slide>
                                      </>
                                    );
                                  });
                              })}
                          </Grid>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              position: "fixed",
                              bottom: "0px",
                              width: "95%",
                              padding: "28px",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => setItemGrpId("")}
                              color="error"
                              className={styles.largeButton}
                              id="choose_clbtn"
                            >
                              Back
                            </Button>

                            <Button
                              variant="contained"
                              onClick={() => setItemGrpId("")}
                              className={styles.largeButton}
                              color="primary"
                              id="nextbtn"
                            >
                              Next
                            </Button>
                          </Box>
                        </Dialog>
                      </>
                    );
                  })
                : ItemsWithGrp &&
                  ItemsWithGrp.map((it) => {
                    return (
                      <>
                        <Slide key={it.name} direction="up" in={true}>
                          {/* <Grid item xl={2} lg={3} md={3} sm={4} xs={6} id="product-grid" */}
                          <Card
                            className={styles.card}
                            onClick={() =>
                              it["userId"]
                                ? productClickHandler(it)
                                : itemGroupHandle(it.id)
                            }
                            style={{ width: "252px" }}
                            id="product"
                          >
                            <CardActionArea
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingTop: "4px",
                              }}
                            >
                              <CardMedia
                                component="img"
                                alt={it.name}
                                image={`${baseURL}/` + it.image}
                                className={styles.media}
                                id="img-product"
                                onError={imageOnErrorHandler}
                              />

                              {it["cat_type"] ? (
                                <div className={styles.veg_img}>
                                  {it.cat_type.toUpperCase() === "VEG" ? (
                                    <img
                                      src="/images/veg.png"
                                      width="30px"
                                      height="30px"
                                    />
                                  ) : (
                                    <img
                                      src="/images/Non-veg.png"
                                      width="30px"
                                      height="30px"
                                    />
                                  )}
                                </div>
                              ) : (
                                " "
                              )}
                              <CardContent className={styles.cardFooter}>
                                <Box className={styles.foot}>
                                  <Typography
                                    gutterBottom
                                    className={styles.prod_title}
                                    variant="h6"
                                    color="primary"
                                    component="p"
                                    id="pro_name"
                                    // style={{ whiteSpace: "nowrap",overflow: "hidden", textOverflow: "ellipsis" }}
                                  >
                                    {it.name}
                                  </Typography>
                                  {it.price ? (
                                    <div
                                      id="pro_price"
                                      style={{
                                        width: "auto",
                                        bottom: "36px",
                                        right: "1px",
                                        padding: "5px",
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      <h3 style={{ margin: "0px" }}>
                                        {selectedCurrency}
                                        {it.price}
                                      </h3>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Slide>

                        <Dialog
                          onClose={() => setItemGrpId("")}
                          aria-labelledby="max-width-dialog-title"
                          fullScreen="sm"
                          open={itemGrpId === it.id}
                          fullWidth={true}
                          // maxWidth="sm"
                          maxWidth={state.widthScreen ? "lg" : "md"}
                        >
                          <AppBar
                            sx={{ position: "relative", marginBottom: "18px" }}
                          >
                            <Toolbar>
                              {/* <IconButton
                                  edge="start"
                                  color="error"
                                  style={{fontSize:"18px"}}
                                  onClick={()=>setItemGrpId("")}
                                  aria-label="close"
                                >
                                Back
                                </IconButton> */}
                              <Typography
                                style={{ marginLeft: "25px" }}
                                color="secondary"
                                sx={{ ml: 2, flex: 1 }}
                                variant="h5"
                                component="h5"
                              >
                                {it.name}
                              </Typography>
                            </Toolbar>
                          </AppBar>

                          <Grid
                            container
                            spacing={1}
                            sx={{ height: "80%", padding: "15px" }}
                          >
                            {filterItmGrp
                              .filter((p) => p.id === itemGrpId)
                              .map((item) => {
                                return item.items.elements
                                  .filter((p) => p.available)
                                  .map((product) => {
                                    return (
                                      <>
                                        <Slide
                                          key={product.name}
                                          direction="up"
                                          in={true}
                                        >
                                          <Grid
                                            item
                                            xl={2}
                                            lg={3}
                                            md={3}
                                            sm={4}
                                            xs={6}
                                          >
                                            <Card
                                              className={styles.card}
                                              onClick={() =>
                                                productClickHandler(product)
                                              }
                                            >
                                              <CardActionArea>
                                                <CardMedia
                                                  component="img"
                                                  alt={product.name}
                                                  onError={imageOnErrorHandler}
                                                  image={
                                                    `${baseURL}/` +
                                                    product.image
                                                  }
                                                  className={styles.media}
                                                />
                                                <div>
                                                  <h3 style={{ margin: "0px" }}>
                                                    {selectedCurrency}
                                                    {product.price}
                                                  </h3>
                                                </div>

                                                <div className={styles.veg_img}>
                                                  {/* {product.cat_type.toUpperCase() === "VEG" ?
                                            <img
                                              src="/images/veg.png"
                                              width="30px" height="30px"
                                            />
                                            : <img src="/images/Non-veg.png" width="30px" height="30px" />
              
                                          } */}
                                                </div>
                                                <CardContent
                                                  className={styles.cardFooter}
                                                  style={{ display: "block" }}
                                                >
                                                  <Box className={styles.foot}>
                                                    <Typography
                                                      gutterBottom
                                                      className={
                                                        styles.prod_title
                                                      }
                                                      variant="h6"
                                                      color="primary"
                                                      component="p"
                                                      // style={{width:"150px"}}
                                                    >
                                                      {product.name}
                                                    </Typography>

                                                    {/* <Typography
                                              gutterBottom
                                              variant="p"
                                              color=""
                                              component="p"
                                            >
                                                {product.calories}cal
                                            </Typography> */}
                                                  </Box>
                                                </CardContent>
                                              </CardActionArea>
                                            </Card>
                                          </Grid>
                                        </Slide>
                                      </>
                                    );
                                  });
                              })}
                          </Grid>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              position: "fixed",
                              bottom: "0px",
                              width: "95%",
                              padding: "28px",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => setItemGrpId("")}
                              color="error"
                              className={styles.largeButton}
                              id="choose_clbtn"
                            >
                              Back
                            </Button>

                            <Button
                              variant="contained"
                              onClick={() => setItemGrpId("")}
                              className={styles.largeButton}
                              color="primary"
                              id="nextbtn"
                            >
                              Next
                            </Button>
                          </Box>
                        </Dialog>
                      </>
                    );
                  })}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box className="orderscreen_foot">
        <Box>
          {orderItems.length && false ? (
            <Box
              className={[styles.bordered, styles.space]}
              style={{
                textAlign: "center",
                fontSize: "26px",
                lineHeight: "30px",
              }}
            >
              My Order - {orderType} | Tax: {selectedCurrency}
              {taxPrice} | Total: {selectedCurrency}
              {totalPrice} | Items: {itemsCount}
            </Box>
          ) : (
            ""
          )}
          {false ? (
            <Box className={[styles.row, styles.around, styles.space]}>
              <Button
                onClick={() => {
                  clearOrder(dispatch);
                  props.history.push(
                    "/choose?" + window.location.href.split("?")[1]
                  );
                }}
                variant="outlined"
                color="primary"
                className={styles.largeButton}
                id="choose_clbtn"
              >
                Cancel
              </Button>

              <Button
                onClick={previewOrderHandler}
                variant="contained"
                color="primary"
                disabled={orderItems.length === 0}
                className={styles.largeButton}
              >
                NEXT
              </Button>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </Box>
  );
}
